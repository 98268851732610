import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { urlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import useSite from '../hooks/use-site'

const SEO = ({ title, description, keywords, image, scripts = [] }) => {
  const { pathname } = useLocation()
  const site = useSite()

  const siteTitle = title || site.title
  const siteDescription = description || site.description
  const siteUrl = `${pathname}` || ''
  const siteKeywords = keywords && keywords.length > 0 ? keywords.join(', ') : []
  const metaImage =
    image && image.asset
      ? urlFor(buildImageObj(image)).width(1200).height(630).url()
      : urlFor(buildImageObj(site.image)).width(1200).height(630).url()

  return (
    <Helmet>
      <title>{siteTitle}</title>

      <meta name="description" content={siteDescription} />
      {keywords && <meta name="keywords" content={siteKeywords} />}
      {metaImage && <meta name="image" content={metaImage} />}

      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={siteDescription} />
      {metaImage && <meta property="og:image" content={metaImage} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={siteDescription} />
      {metaImage && <meta name="twitter:image" content={metaImage} />}

      {/* Fonts */}
      <link
        href="//webfonts2.radimpesko.com/RP-W-5fd87d706f3e0c36a4000007.css"
        rel="stylesheet"
        type="text/css"
      ></link>
      <link rel="stylesheet" href="https://use.typekit.net/wpp7bfb.css"></link>

      {scripts.map(s => (
        <script defer src={s}></script>
      ))}
    </Helmet>
  )
}

export default SEO
